import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Auditoria, GenericObject, ImportacoesTiff } from "../../interfaces";
import {
  alpha,
  Box,
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { DialogVersion } from "../Dashboard/DialogVersion";
import logoLight from "../../assets/logo-pos.png";
import logo from "../../assets/logo.png";
import { environments } from "../../environments";
import pj from "./../../../package.json";
import moment from "moment";
import { RFeature, RLayerTile, RLayerVector, RMap, ROSM, RStyle } from "rlayers";
import WKB from "ol/format/WKB.js";
import WKT from "ol/format/WKT.js";
import GeoJSON from "ol/format/GeoJSON";
import { Geometry } from "ol/geom";
import { getCenter, getHeight, getWidth } from "ol/extent";
import { apiGateway } from "../../repositories/api.gateway";
import { getForms, getImportacoesTiff } from "../../services/api";
import { ColorModeContext } from "../../providers/Theme";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { Feature } from "ol";

interface RouteParams {
  id: string;
}

type IGeoJSON = {
  type: string;
  coordinates: GenericObject[];
  crs?: {
    type: string;
    properties: {
      name: string;
    };
  };
};

type IWKT = string;

type IWKB = string;

type GeoFormat = "GeoJSON" | "WKT" | "WKB" | "Unknown format";

export default function AuditoriaCadastroById() {
  const history = useHistory();
  const theme = useTheme();
  const color = useContext(ColorModeContext);
  const { id } = useParams<RouteParams>();

  const [auditoria, setAuditoria] = useState<GenericObject | undefined>(undefined);
  const [view, setView] = useState<any>({ center: [0, 0], zoom: 1 });
  const [newGeo, setNewJson] = useState<Geometry>();
  const [oldGeo, setOldJson] = useState<Geometry>();
  const [metadata, setMetadata] = useState<GenericObject[]>([]);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [basemaps, setBasemaps] = useState<ImportacoesTiff[]>();
  const [isHoveringLayerSwitcher, setIsHoveringLayerSwitcher] = useState<boolean>(false);
  const [currentBasemapIdx, setCurrentBasemapIdx] = useState<number>(0);

  const mapStyles = {
    newGeo: `#ff0000`,
    oldGeo: `#0000ff`,
  };

  const mapRef = useRef<RMap | null>(null);

  const formatWkt = new WKT();
  const formatWkb = new WKB();

  useEffect(() => {
    if (id) {
      getAuditoriaById(id);
    }
  }, [id]);

  useEffect(() => {
    fetchForms();
  }, []);

  useEffect(() => {
    if (auditoria) {
      switchTypeMap();
    }
  }, [auditoria]);

  useEffect(() => {
    if (!basemaps) {
      getImportacoesTiff().then((data) => {
        if (!data) return;
        data.filter((e) => e.url);
        setBasemaps(data);
      });
    }
  }, []);

  async function getAuditoriaById(id: string): Promise<Auditoria> {
    const response = await apiGateway(`/rest/auditoria?id=eq.${id}`);
    if (response.data.length > 0) {
      setAuditoria(response.data[0]);
    } else {
      history.push("/auditoria/cadastro");
    }
    return response.data;
  }

  function extractCampos(data: GenericObject) {
    return Object.values(data).flatMap((itemArray) =>
      itemArray.flatMap((item: GenericObject) => item.projetosCamadasFormulariosCampos),
    );
  }

  async function fetchForms() {
    const forms = await getForms();
    if (forms) {
      setMetadata(extractCampos(forms));
    }
  }

  function labelValue(tblNomeColuna: string, value: string) {
    const data = metadata.find((item) => item.tblNomeColuna === tblNomeColuna);
    if (data && data.camposSelect) {
      const label = data.camposSelect.find((item: GenericObject) => item.tblValorDb === String(value))?.nome;
      if (label) {
        return label;
      }
      return value;
    }
    return value;
  }

  function haveSameKeys(obj1: GenericObject, obj2: GenericObject): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return true;
    } else {
      return false;
    }
  }

  function formatValue(key: string, value: string) {
    if (!auditoria) return;

    const isObject = value && typeof value === "object" && !Array.isArray(value);

    if (isObject) {
      return JSON.stringify(value, null, 2);
    }

    const label = labelValue(key, value);

    if (label) {
      return label;
    }

    if (value === "") {
      return "-";
    }

    return String(value);
  }

  function currentComponent() {
    if (!auditoria) return;

    if (auditoria.body_antigo === null && auditoria.body_novo !== null) {
      const keys_body_novo = Object.keys(auditoria.body_novo);

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campo</TableCell>
              <TableCell align="right">Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys_body_novo.map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right" sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {formatValue(key, auditoria.body_novo[key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    if (auditoria.body_novo === null && auditoria.body_antigo !== null) {
      const keys_body_antigo = Object.keys(auditoria.body_antigo);

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campo</TableCell>
              <TableCell align="right">Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys_body_antigo.map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right" sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {formatValue(key, auditoria.body_antigo[key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    if (haveSameKeys(auditoria.body_antigo, auditoria.body_novo)) {
      return (
        <Box sx={{ display: "flex", gap: "6px", flexDirection: "column" }}>
          <Box sx={{ verticalAlign: "top" }}>
            <Box sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
              <b>Body antigo</b>: {JSON.stringify(auditoria?.body_antigo, null, 2)}
            </Box>
          </Box>
          <Box sx={{ verticalAlign: "top" }}>
            <Box sx={{ width: "100%", wordBreak: "break-word", overflowWrap: "break-word" }}>
              <b>Body novo</b>: {JSON.stringify(auditoria?.body_novo, null, 2)}
            </Box>
          </Box>
        </Box>
      );
    }

    const keys_body_novo = Object.keys(auditoria.body_novo || {});
    const keys_body_antigo = Object.keys(auditoria.body_antigo || {});

    const allKeys = [...keys_body_novo, ...keys_body_antigo].filter((key, index, self) => self.indexOf(key) === index);

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Campo</TableCell>
            <TableCell align="right">Antigo</TableCell>
            <TableCell align="right">Novo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...allKeys].map((key) => {
            const oldValue = formatValue(key, auditoria.body_antigo[key]);
            const newValue = formatValue(key, auditoria.body_novo[key]);

            if (oldValue === newValue) {
              return null;
            }

            return (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                <TableCell align="right" sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {oldValue}
                </TableCell>
                <TableCell align="right" sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {newValue}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  function handleBack() {
    history.push("/auditoria/cadastro");
  }

  function handleRevert() {
    if (!auditoria) {
      alert("Erro ao reverter modificações");
      return;
    }

    if (auditoria.body_antigo === null && auditoria.body_novo !== null) {
      console.log("Insert");
    }

    if (auditoria.body_novo === null && auditoria.body_antigo !== null) {
      console.log("Delete");
    }

    if (auditoria.body_novo !== null && auditoria.body_antigo !== null) {
      console.log("Update");
    }
  }

  function getZoom(extent: number[]) {
    const width = getWidth(extent);
    const height = getHeight(extent);

    const zoom = Math.round(Math.log2(40075016.68557849 / Math.max(width, height)));

    if (zoom > 18) {
      return 18;
    }

    return zoom;
  }

  function validateGeoFormat(data: IGeoJSON | IWKT | IWKB): GeoFormat {
    if (typeof data === "object" && data !== null && data.type && data.coordinates) {
      return "GeoJSON";
    } else if (typeof data === "string" && data.startsWith("POLYGON(")) {
      return "WKT";
    } else if (typeof data === "string" && /^[0-9A-Fa-f]+$/.test(data)) {
      return "WKB";
    } else {
      return "Unknown format";
    }
  }

  function setCenterMap(geometry?: Geometry) {
    if (geometry) {
      const extent = geometry.getExtent();

      const center = getCenter(extent);
      const zoom = getZoom(extent);

      setView({
        center: center,
        zoom: zoom,
      });
    }
  }

  function getGeoJson(geo: string) {
    let feature = new GeoJSON().readFeature(geo, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    const geometry = (feature as Feature<Geometry>).getGeometry();

    return geometry;
  }

  function getWktJson(geo: string) {
    let feature = formatWkt.readFeature(geo, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    const geometry = (feature as Feature<Geometry>).getGeometry();

    return geometry;
  }

  function setWkbJson(geo: string) {
    let feature = formatWkb.readFeature(geo, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    });

    const geometry = (feature as Feature<Geometry>).getGeometry();

    return geometry;
  }

  function findGeomNovo(obj: GenericObject): GeoFormat | null {
    if (!obj || typeof obj !== "object") return null;

    if (obj.geom) return obj.geom;

    if (obj.body_novo && obj.body_novo.geom) return obj.body_novo.geom;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const result = findGeomNovo(obj[key]);
        if (result) return result;
      }
    }

    return null;
  }

  function findGeomAntigo(obj: GenericObject): GeoFormat | null {
    if (!obj || typeof obj !== "object") return null;

    if (obj.geom) return obj.geom;

    if (obj.body_antigo && obj.body_antigo.geom) return obj.body_antigo.geom;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const result = findGeomAntigo(obj[key]);
        if (result) return result;
      }
    }

    return null;
  }

  function switchTypeMap() {
    if (!auditoria) return;

    const geomNovo = findGeomNovo(auditoria.body_novo);
    const geomAntigo = findGeomAntigo(auditoria.body_antigo);

    const handlers = {
      GeoJSON: getGeoJson,
      WKT: getWktJson,
      WKB: setWkbJson,
    };

    if (geomNovo) {
      const newMap = validateGeoFormat(geomNovo);
      const newHandler = handlers[newMap as keyof typeof handlers];

      if (newHandler) {
        const newGeo = newHandler(geomNovo);
        setNewJson(newGeo);
        setCenterMap(newGeo);
        setShowMap(true);
      } else {
        console.error(`No handler found for format: ${newMap}`);
      }
    }

    if (geomAntigo) {
      const oldMap = validateGeoFormat(geomAntigo);

      const oldHandler = handlers[oldMap as keyof typeof handlers];

      if (oldHandler) {
        const oldJson = oldHandler(geomAntigo);
        setOldJson(oldJson);
        setCenterMap(oldJson);
        setShowMap(true);
      } else {
        console.error(`No handler found for format: ${oldMap}`);
      }
    }
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box
        sx={{
          width: "95vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          id="logo-container"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            width: "100%",
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <img className="logo-dashboard" src={theme.palette.mode === "dark" ? logo : logoLight} alt="logo" />
              <Divider
                sx={{
                  marginRight: theme.spacing(1),
                  marginLeft: theme.spacing(1),
                }}
                orientation="vertical"
                flexItem
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight={"700"}
                  fontStyle={{
                    color: alpha(theme.palette.text.secondary, 0.4),
                  }}
                >
                  Auditoria
                </Typography>

                <DialogVersion>
                  {pj.version} - {environments.ambiente}{" "}
                </DialogVersion>
              </Box>
            </Box>

            <Box id="dark-mode-toggle" sx={{ display: "flex", gap: theme.spacing(1) }}>
              <IconButton onClick={color.toggleColorMode} color="inherit">
                {theme.palette.mode === "dark" ? <Brightness7Icon /> : <DarkModeIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: 3, display: "flex", gap: 3, flexDirection: "column", minHeight: "100%", flexGrow: 1 }}>
        <Box>
          {
            <Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "12px",
                    marginTop: "12px",
                  }}
                >
                  <Box sx={{ margin: 1, width: "100%" }}>
                    <Box>
                      <Box sx={{ display: "flex", gap: "12px", width: "100%" }}>
                        <Box sx={{ display: "flex", gap: "6px", flexDirection: "column", width: "400px" }}>
                          <Box>
                            <b>Descrição</b>
                          </Box>
                          <Box>
                            <b>id: </b>
                            <span>{auditoria?.id}</span>
                          </Box>
                          <Box>
                            <b>Data: </b>
                            <span>{moment(auditoria?.data).format("DD/MM/YYYY HH:mm:ss")}</span>
                          </Box>
                          <Box>
                            <b>Camada: </b>
                            <span>{auditoria?.camada}</span>
                          </Box>
                          <Box>
                            <b>Descrição: </b>
                            <span>{auditoria?.descricao}</span>
                          </Box>
                          <Box>
                            <b>Usuário: </b>
                            <span>{auditoria?.usuario}</span>
                          </Box>
                          <Box>
                            <b>Mensagem: </b>
                            <span>-</span>
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", gap: "6px", flexDirection: "column", width: "100%" }}>
                          <b>Valores modificados</b>
                          {currentComponent()}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        </Box>

        {showMap && (
          <Box sx={{ position: "relative" }}>
            <RMap
              width={"100%"}
              height={"calc(60vh)"}
              initial={view}
              view={[view, setView]}
              ref={mapRef}
              // noDefaultControls={true}
              // noDefaultInteractions={true}
            >
              <ROSM />

              <RLayerTile url={"http://basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"} zIndex={2} />

              {basemaps && basemaps.length > 0 && basemaps[currentBasemapIdx].url && (
                <RLayerTile
                  url={`${basemaps[currentBasemapIdx].url}{z}/{x}/{y}.${basemaps[currentBasemapIdx].extensao}`}
                  zIndex={3}
                />
              )}

              {oldGeo && (
                <RLayerVector zIndex={4}>
                  <RFeature geometry={oldGeo}>
                    <RStyle.RStyle>
                      <RStyle.RStroke color={mapStyles.oldGeo} width={5} />
                      <RStyle.RFill color={mapStyles.oldGeo + 10} />
                      <RStyle.RCircle radius={10}>
                        <RStyle.RFill color={mapStyles.oldGeo} />
                      </RStyle.RCircle>
                    </RStyle.RStyle>
                  </RFeature>
                </RLayerVector>
              )}

              {newGeo && (
                <RLayerVector zIndex={5}>
                  <RFeature geometry={newGeo}>
                    <RStyle.RStyle>
                      <RStyle.RStroke color={mapStyles.newGeo} width={5} />
                      <RStyle.RFill color={mapStyles.newGeo + 10} />
                      <RStyle.RCircle radius={10}>
                        <RStyle.RFill color={mapStyles.oldGeo} />
                      </RStyle.RCircle>
                    </RStyle.RStyle>
                  </RFeature>
                </RLayerVector>
              )}

              <Box>
                {basemaps && basemaps.length > 0 && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                      cursor: "pointer",
                      height: isHoveringLayerSwitcher ? `unset` : "80px",
                      transition: "height 0.3s ease-in-out",
                      display: isHoveringLayerSwitcher ? "grid" : "block",
                      gridTemplateColumns: "repeat(3, 1fr)",
                      gap: "10px",
                      zIndex: 6,
                    }}
                    onMouseEnter={() => setIsHoveringLayerSwitcher(true)}
                    onMouseLeave={() => setIsHoveringLayerSwitcher(false)}
                  >
                    {isHoveringLayerSwitcher ? (
                      <>
                        {basemaps
                          .filter((b) => b.url)
                          .map((b, i) => {
                            return (
                              <Box
                                sx={{
                                  paddingBottom: isHoveringLayerSwitcher ? "5px" : 0,
                                  display: "block",
                                  height: "auto",
                                }}
                                onClick={() => setCurrentBasemapIdx(i)}
                              >
                                <Tooltip title={basemaps[i].nome} placement="bottom-end">
                                  <Box
                                    component="img"
                                    sx={{
                                      width: "80px",
                                      height: "80px",
                                      borderRadius: "5px",
                                      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75);",
                                    }}
                                    src={
                                      basemaps[i].preview ||
                                      `https://via.placeholder.com/80x80/333840?text=${basemaps[i].nome}`
                                    }
                                    alt="Basemap"
                                  />
                                </Tooltip>
                              </Box>
                            );
                          })}
                      </>
                    ) : (
                      <Box
                        sx={{
                          paddingBottom: isHoveringLayerSwitcher ? "5px" : 0,
                        }}
                      >
                        <Tooltip title={basemaps[currentBasemapIdx].nome} placement="top">
                          <Box
                            component="img"
                            sx={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "5px",
                              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75);",
                            }}
                            src={
                              basemaps[currentBasemapIdx].preview ||
                              `https://via.placeholder.com/80x80/333840?text=${basemaps[currentBasemapIdx].nome}`
                            }
                            alt="Basemap"
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  bottom: "30px",
                  right: "12px",
                  backgroundColor: "rgba(255, 255, 255, 0.75)",
                  padding: "6px",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75);",
                  zIndex: 10,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "6px",
                    flexDirection: "row",
                    color: "black",
                  }}
                >
                  <Box
                    sx={{
                      border: "4px solid",
                      borderColor: mapStyles.newGeo,
                      width: "20px",
                      height: "20px",
                    }}
                  ></Box>{" "}
                  &nbsp; Nova geometria
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "6px",
                    flexDirection: "row",
                    color: "black",
                  }}
                >
                  <Box
                    sx={{
                      border: "4px solid",
                      borderColor: mapStyles.oldGeo,
                      width: "20px",
                      height: "20px",
                    }}
                  ></Box>{" "}
                  &nbsp; Geometria antiga
                </Box>
              </Box>
            </RMap>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", gap: "6px", justifyContent: "end", padding: `6px` }}>
        <Button
          variant="contained"
          color="success"
          sx={{ minWidth: "10%", backgroundColor: "#F26C00" }}
          onClick={handleRevert}
          disabled
        >
          REVERTER MODIFICAÇÕES
        </Button>
        <Button variant="contained" color="success" sx={{ minWidth: "10%" }} onClick={handleBack}>
          VOLTAR
        </Button>
      </Box>
    </Box>
  );
}
